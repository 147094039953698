import { useContext } from 'react';
import { Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useLocation, DialogStyle, findMatchingRoute, RouteSwitcher, OverlayContentWrapper, CloseButton } from '@adac/core-view';

import StoreContext from '../../stores';

import ComingSoon from '../overlays/ComingSoon';

export default observer(() => {
  const stores = useContext(StoreContext);
  const location = useLocation();
  const { getPrefixedLink } = location;

  const routes: JSX.Element[] = [<Route path={getPrefixedLink('/hello')} exact render={() => <div>Hello</div>} />];

  const foundMatchingRoute = !!findMatchingRoute({ location, routes });

  const isComingSoon = location.search.match(/[?&]coming-soon(\/|&|$)/gi) !== null;
  const { showOverlay, OverlayContent, isFullPageOverlay } = stores.ui;
  const hasValidRoute = foundMatchingRoute;
  const open = isComingSoon || hasValidRoute || showOverlay;

  return (
    <DialogStyle open={open} column end='true'>
      {hasValidRoute && <RouteSwitcher routes={routes} />}
      {isComingSoon && <ComingSoon />}
      {showOverlay && (
        <OverlayContentWrapper fullPage={isFullPageOverlay}>
          <CloseButton onClick={() => stores.ui.setOverlay()} />
          <>{OverlayContent}</>
        </OverlayContentWrapper>
      )}
    </DialogStyle>
  );
});
