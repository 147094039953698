import { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import { Text, Button } from '@adac/core-view';

const Styles = styled.div`
  display: flex;
  & > * {
    margin: 0 24px !important;
  }
`;

interface PageIndicatorProps {
  selected?: boolean;
}

const PageIndicator = styled(Text)<PageIndicatorProps>`
  font-weight: bold;
  color: ${(props) => (props.selected ? props.theme.colors.backgroundDark : props.theme.colors.border)};
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  pages: number;
  onIndexChange?: (index: number) => void;
}

export default (props: Props) => {
  const { pages, onIndexChange } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const setSelected = (index: number) => {
    setSelectedIndex(index);
    if (onIndexChange) onIndexChange(index);
  };

  return (
    <Styles>
      {Array.from({ length: pages }, (_, i) => i).map((page, index) => (
        <Button key={index} onClick={() => setSelected(index)}>
          <PageIndicator selected={selectedIndex === index}>{page + 1}</PageIndicator>
        </Button>
      ))}
    </Styles>
  );
};
