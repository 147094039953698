import { useContext, useEffect, useState } from 'react';
import { getMyCompany } from '../networking/driverEndpoints';
import StoresContext from '../stores';

export const useMyCompany = () => {
  const { company } = useContext(StoresContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const res = await getMyCompany();
        company.setMe(res);
      } catch (error) {
        company.setMe(undefined);
      } finally {
        setIsLoading(false);
      }
    };
    getCompanies();
  }, [company]);

  return {
    company: company.getMe,
    isLoading,
  };
};
