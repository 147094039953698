import { Company, getApiRoutes } from '@adac/core-model';
import { CreateOrUpdateDriver, request } from '@adac/core-view';

export const createDriver = async (data: CreateOrUpdateDriver) => {
  const url = getApiRoutes().driverAdmin.create;
  const res = await request<object, CreateOrUpdateDriver>(url, {
    method: 'POST',
    data,
  });
  return res;
};

export const updateDriver = async (driverId: string | number, data: CreateOrUpdateDriver) => {
  const url = getApiRoutes().driverAdmin.get(driverId);
  const res = await request(url, {
    method: 'PUT',
    data,
  });
  return res;
};

export const deleteDriver = async (driverId: string | number) => {
  const url = getApiRoutes().driverAdmin.get(driverId);
  try {
    const res = await request(url, {
      method: 'DELETE',
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getMyCompany = async () => {
  const url = getApiRoutes().partner.getMyCompany;
  return request<Company>(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
