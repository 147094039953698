import styled from 'styled-components';

import { Cancellation, Document, ListWithHeaders, getClaimReference } from '@adac/core-model';
import { ChildrenProps, Text, View } from '@adac/core-view';

import { Case } from '../../networking/getCases';
import { Params, Title } from '../ListView';

const DescriptionStyles = styled(View)`
  position: relative;
`;
const DescriptionAddress = styled(Text)`
  font-size: 1.6rem;
`;
const DescriptionCaseId = styled(Text)`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.gray};
`;

interface DescriptionProps extends ChildrenProps {
  address: string;
  caseId: string;
}

export const Description = ({ address, caseId, children }: DescriptionProps) => (
  <DescriptionStyles>
    <DescriptionAddress>{address}</DescriptionAddress>
    <DescriptionCaseId>{caseId}</DescriptionCaseId>
    {children}
  </DescriptionStyles>
);

export interface CaseTableCellItem extends Title {
  key: string;
  link: string;
  commissioningReference: string;
  street: string;
  place: string;
  timeOfOrder: Date;
  invoiceDocument: Document | null | undefined;
  cancellation: Cancellation;
  scheduledTs: Date | null;
}

export const getCasesIntoCellItems = async (
  accessToken: string,
  getCases: (token: string, params?: Params) => Promise<ListWithHeaders<Case>>,
  params?: Params
) => {
  const cases = await getCases(accessToken, params);
  const cellItems = cases.data.map(
    (c: Case): CaseTableCellItem => ({
      key: `${c.id}`,
      link: `/cases/${c.cypheredToken}`,
      commissioningReference: getClaimReference(c.commissioningReference),
      title: `${c.customerAddressStreet} ${c.customerAddressNumber}`,
      street: `${c.customerAddressStreet} ${c.customerAddressNumber}`,
      place: `${c.customerAddressZip} ${c.customerAddressCity}`,
      timeOfOrder: c.createdAt,
      invoiceDocument: c.invoiceDocument,
      cancellation: c.cancellation,
      scheduledTs: c.scheduledTs,
    })
  );

  return { cellItems, total: cases.total };
};
