import { useContext } from 'react';

import { __ } from '@adac/core-model';
import { ErrorMessage, Icon, RoundImage, TableView } from '@adac/core-view';

import { getDrivers } from '../../networking/getDrivers';
import StoresContext from '../../stores';
import { ListView, Title } from '../ListView';

const mapRole = (role: string) => {
  if (role === 'company') return __('Role:company');
  return role;
};

interface DriverCellItem extends Title {
  key: string;
  link: string;
  description: string;
  customOrderView: JSX.Element;
}

export const getDriversIntoCellItems = async (dispacherCallback: (dispatchers: number) => void) => {
  const drivers = await getDrivers();
  let dispatchers = 0;

  const cellItems: DriverCellItem[] = drivers.data.map((d) => {
    const roles = ((d.user?.roles ?? []) as { name: string }[]).map(({ name }: { name: string }) => name);
    if (roles.includes('dispatcher')) {
      dispatchers += 1;
    }

    return {
      title: `${d.firstName} ${d.familyName}`,
      description: `${d.user ? mapRole(d.user.roles[0].name) : __('Driver')}`,
      key: `${d.id}`,
      customOrderView: <RoundImage image={d.photo} placeholder={<Icon name='person' />} />,
      link: `/driver/edit/${d.id}`,
    };
  });

  dispacherCallback(dispatchers);
  return { cellItems, total: drivers.total };
};

export default () => {
  const { ui: uiStore } = useContext(StoresContext);

  const dispatcherCounterCallback = (dispatchers: number) => {
    if (dispatchers > 1) {
      uiStore.setOverlay(<ErrorMessage>{__('Warning: You have 2 dispatchers defined for your company')}</ErrorMessage>);
    }
  };

  return (
    <ListView
      getList={() => getDriversIntoCellItems(dispatcherCounterCallback)}
      renderList={(items: DriverCellItem[]) => <TableView allCellsOpen cellItems={items} rightView={<Icon name='right' />} />}
    />
  );
};
