import { observer } from 'mobx-react';
import { useContext } from 'react';

import { BasicDevTool } from '@adac/core-view';

import StoreContext from '../../stores';

export default observer(() => {
  const stores = useContext(StoreContext);

  return (
    <BasicDevTool stores={stores}>
      <section />
    </BasicDevTool>
  );
});
