import { useContext } from 'react';
import styled from 'styled-components';

import { Button, ErrorMessage, FlexView, Icon } from '@adac/core-view';

import { __ } from '@adac/core-model';
import { observer } from 'mobx-react';
import { useMyCompany } from '../../hooks/useMyCompany';
import StoresContext from '../../stores';
import ProfileOverlay from '../overlays/Profile';

const Styles = styled(FlexView)`
  margin-right: 24px;
  margin-left: auto;

  button {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    grid-gap: 8px;
  }
`;

const Profile = () => {
  const { ui: uiStore, auth: authStore, company: companyStore } = useContext(StoresContext);
  const { company: myCompany } = useMyCompany();

  const logout = () => {
    uiStore.setOverlay();
    uiStore.toggleFullPageOverlay(false);
    authStore.deleteToken();
    companyStore.clear();
  };

  const circleProfileIcon = 'profileCircle';
  const closeProfileIcon = 'close';

  const toggleProfileOverlay = () => {
    if (!uiStore.showOverlay && authStore.isLoggedIn) {
      uiStore.setOverlay(<ProfileOverlay />);
      uiStore.toggleFullPageOverlay(true);
    } else {
      uiStore.setOverlay();
      uiStore.toggleFullPageOverlay(false);
    }
  };

  if (!authStore.isLoggedIn) return <></>;

  return (
    <Styles right>
      {myCompany && !myCompany?.active && <ErrorMessage>{__('Your company is disabled!')}</ErrorMessage>}
      <Button onClick={logout}>
        <Icon name='logoutSquare' />
      </Button>
      <Button onClick={toggleProfileOverlay}>
        <Icon name={uiStore.showOverlay ? closeProfileIcon : circleProfileIcon} />
      </Button>
    </Styles>
  );
};

export default observer(Profile);
