import { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  AddressOverlayContainer,
  CreateOrUpdateDriver,
  DriverDeleteButton,
  ErrorMessage,
  Loader,
  ManageDriver,
  Page,
  SubPage,
  SubTitle,
  themeConfig,
  useLocation,
  useValidationSchema,
} from '@adac/core-view';

import { __, isDriverBoss, UserRole } from '@adac/core-model';

import styled from 'styled-components';
import { useMyCompany } from '../../hooks/useMyCompany';
import useSelectedDriver from '../../hooks/useSelectedDriver';
import { deleteDriver, updateDriver } from '../../networking/driverEndpoints';
import StoreContext from '../../stores';

export type LocalizeableError = {
  toLocalizedString: () => string;
};

const DeleteButton = styled(DriverDeleteButton)`
  border: 1px solid ${themeConfig.colors.warning};
  margin: 16px 0px;
  padding: 13px 0px;
  border-radius: 4px;
`;

export const EditDriver = (props: RouteComponentProps<{ driverId: string; action: string }>) => {
  const { auth: authStore, ui: uiStore } = useContext(StoreContext);
  const { goBack } = useLocation();
  const { company: myCompany, isLoading: isCompanyLoading } = useMyCompany();

  const driverId = props.match.params.driverId;
  const { driver, isLoading: isDriverLoading } = useSelectedDriver(driverId);
  const validationSchema = useValidationSchema(driver);

  const onSubmit = async (values: CreateOrUpdateDriver) => {
    try {
      if (driverId) {
        await updateDriver(driverId, values);
      }
      goBack();
    } catch (error) {
      uiStore.setOverlay(<ErrorMessage>{__((error as LocalizeableError)?.toLocalizedString() ?? `${error}`)}</ErrorMessage>);
    }
  };

  const onDeleteDriver = async () => {
    await deleteDriver(driverId);
    goBack();
  };

  if (!isDriverLoading && driverId && !Number.isNaN(driverId) && !driver) return <SubTitle>{__('Driver not found')}</SubTitle>;

  // only boss can edit boss data
  if (!authStore.isUserRoleBoss && isDriverBoss(driver)) {
    return <ErrorMessage>{__('You do not have permission to edit boss')}</ErrorMessage>;
  }

  return (
    <Page>
      <AddressOverlayContainer>
        <SubPage>
          <Loader isLoading={isCompanyLoading || isDriverLoading}>
            {driver && myCompany && (
              <ManageDriver
                companyId={myCompany.id}
                driver={driver}
                onSubmit={onSubmit}
                onBack={goBack}
                validationSchema={validationSchema}
                disablePrivatePhoneForBoss
              >
                {!driver?.user?.roles?.find((role) => role.name === UserRole.COMPANY) && <DeleteButton onClick={onDeleteDriver} />}
              </ManageDriver>
            )}
          </Loader>
        </SubPage>
      </AddressOverlayContainer>
    </Page>
  );
};
