import { useContext } from 'react';

import {
  AddressOverlayContainer,
  CreateOrUpdateDriver,
  ErrorMessage,
  Loader,
  ManageDriver,
  Page,
  SubPage,
  Title,
  useLocation,
  useValidationSchema,
} from '@adac/core-view';

import { __ } from '@adac/core-model';

import { useMyCompany } from '../../hooks/useMyCompany';
import { createDriver } from '../../networking/driverEndpoints';
import StoreContext from '../../stores';
import { LocalizeableError } from './EditDriver';

export const CreateDriver = () => {
  const { ui: uiStore } = useContext(StoreContext);
  const { goBack } = useLocation();
  const { company, isLoading } = useMyCompany();

  const validationSchema = useValidationSchema(undefined);

  const onSubmit = async (values: CreateOrUpdateDriver) => {
    try {
      await createDriver(values);
      goBack();
    } catch (error) {
      uiStore.setOverlay(<ErrorMessage>{__((error as LocalizeableError)?.toLocalizedString() ?? `${error}`)}</ErrorMessage>);
    }
  };

  return (
    <Page>
      <AddressOverlayContainer>
        <SubPage>
          <Title>Neuen Mitarbeiter hinzufügen</Title>
          <Loader isLoading={isLoading}>
            {company && (
              <ManageDriver
                companyId={company.id}
                driver={undefined}
                onSubmit={onSubmit}
                onBack={goBack}
                validationSchema={validationSchema}
                disablePrivatePhoneForBoss
              />
            )}
          </Loader>
        </SubPage>
      </AddressOverlayContainer>
    </Page>
  );
};
