import { useContext } from 'react';
import styled from 'styled-components';

import { __, config } from '@adac/core-model';
import {
  Button,
  ButtonTitle,
  ChildrenProps,
  CommonButtonProps,
  FlexView,
  Icon,
  LoadingSpinner,
  NetworkLoader,
  Text,
  TextProps,
  View,
  getCompanyStatusText,
  getCompanyTypeText,
} from '@adac/core-view';

import { Link } from 'react-router-dom';
import { useMyCompany } from '../../hooks/useMyCompany';
import StoresContext from '../../stores';

const Styles = styled(View)`
  position: relative;
  z-index: 11;

  background-color: ${(props) => props.theme.colors.cta};
  padding: 16px;
  width: 100%;

  border-top: 1px solid ${(props) => props.theme.colors.background};
`;

const ColumnsStyles = styled(View)`
  display: flex;

  margin-bottom: 16px;
`;

const ImageColumn = styled(View)``;

const TextColumn = styled(View)`
  margin: 0 16px;
  flex: 1;
`;

const ProfileTitle = styled(Text)`
  font-weight: bold;
`;

const ProfileText = styled(Text)`
  font-size: 1.6rem;
`;

interface ZipStyles extends TextProps {
  valid?: boolean;
}
const ZipStyles = styled(Text)<ZipStyles>`
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 4px;
  background: ${(props) => props.theme.colors.info};
  color: ${(props) => props.theme.colors.background};
  position: relative;
  padding: 3px;
  padding-left: 12px;
  padding-right: 12px;

  ${(props) =>
    props.valid === false &&
    `
    opacity: 0.3;
    text-decoration: line-through;
  `};
`;
interface LinkButtonProps extends ChildrenProps, CommonButtonProps {
  onClick?: () => void;
  link: string;
}

export const LinkButton = ({ children, onClick, style, link, ...props }: LinkButtonProps) => (
  <Link to={link}>
    <Button style={style} {...props} onClick={onClick}>
      {children}
    </Button>
  </Link>
);

export default () => {
  const { ui: uiStore, auth: authStore, company: companyStore } = useContext(StoresContext);
  const { company: myCompany } = useMyCompany();

  const logout = () => {
    uiStore.setOverlay();
    uiStore.toggleFullPageOverlay(false);
    authStore.deleteToken();
    companyStore.clear();
  };

  const hide = () => {
    uiStore.setOverlay();
    uiStore.toggleFullPageOverlay(false);
  };

  const marginBottom = { marginBottom: '10px' };

  return (
    <Styles>
      <NetworkLoader loading={authStore.loading} loadingView={<LoadingSpinner>{__('Loading')}</LoadingSpinner>}>
        <ColumnsStyles>
          <ImageColumn />
          <TextColumn>
            <ProfileTitle>{`${myCompany?.name}`}</ProfileTitle>
            <ProfileText>
              {getCompanyTypeText(myCompany?.type)}, {getCompanyStatusText(myCompany?.active)}
              {!myCompany?.active && ', ('}
              {!myCompany?.active && myCompany?.activeFrom && `${__('Valid from')} ${config.date(myCompany?.activeFrom)}`}
              {!myCompany?.active && myCompany?.activeTo && `${__('Valid to')} ${config.date(myCompany?.activeTo)}`}
              {!myCompany?.active && ')'}
            </ProfileText>
            <br />
            <ProfileTitle>{authStore.userData?.name}</ProfileTitle>
            <ProfileText>{__(`Role:${authStore.userRole}`)}</ProfileText>
            <br />
            <ProfileTitle>{__('Company ID')}</ProfileTitle>
            <ProfileText>{myCompany?.id}</ProfileText>
            <br />
            <ProfileTitle>{__('Contact Manager ID')}</ProfileTitle>
            <ProfileText>{myCompany?.contactManagerId}</ProfileText>
            <br />
          </TextColumn>
          <FlexView column>
            <Link to='/notification' onClick={hide} style={marginBottom}>
              <Icon name='bell' />
            </Link>
            <Link to='/change-password' onClick={hide}>
              <Icon name='lock-pending' />
            </Link>
          </FlexView>
        </ColumnsStyles>
      </NetworkLoader>
      <LinkButton ctaBorder link='/change-password' onClick={hide} style={marginBottom}>
        <ButtonTitle>{__('New password')}</ButtonTitle>
      </LinkButton>
      <LinkButton ctaBorder link='/notification' onClick={hide} style={marginBottom}>
        <ButtonTitle>{__('Notification settings')}</ButtonTitle>
      </LinkButton>
      <Button ctaBorder onClick={logout}>
        <ButtonTitle>{__('Logout')}</ButtonTitle>
      </Button>
    </Styles>
  );
};
