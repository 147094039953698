import { findMatchingRoute, Page, PageTransitionContainer, useLocation } from '@adac/core-view';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { keyGenerator } from '@adac/core-model';
import StoresContext from '../../stores';
import CaseDetail from '../pages/CaseDetail';
import ChangePassword from '../pages/ChangePassword';
import ClosedCases from '../pages/ClosedCases';
import { CreateDriver } from '../pages/CreateDriver';
import Dashboard from '../pages/Dashboard';
import { EditDriver } from '../pages/EditDriver';
import NotificationSettings from '../pages/NotificationSettings';
import OpenCases from '../pages/OpenCases';

export default observer((): JSX.Element => {
  const stores = useContext(StoresContext);
  const location = useLocation();

  const { key, pathname } = location;

  const routes: JSX.Element[] = [
    <Route path='/' exact pageTransition='slideLeft' component={Dashboard} />,
    <Route pageTransition='slideUp' path='/cases/closed' exact component={ClosedCases} />,
    <Route pageTransition='slideUp' path='/cases/open' exact component={OpenCases} />,
    <Route path='/driver/edit/:driverId' exact component={EditDriver} />,
    <Route path='/driver/new' exact component={CreateDriver} />,
    <Route pageTransition='slideUp' path='/change-password' exact component={ChangePassword} />,
    <Route path='/cases/closed' exact pageTransition='slideRight' component={ClosedCases} />,
    <Route path='/cases/:token' pageTransition='slideLeft' component={CaseDetail} />,
    <Route path='/notification' pageTransition='slideUp' component={NotificationSettings} />,
  ];
  // TODO: enable pagetransitions again
  const foundMatchingRoute = findMatchingRoute({ location, routes });
  if (foundMatchingRoute && foundMatchingRoute.props.pageTransition) {
    // NOTE: The reason I don't use pageTranstion just a local reference, using the stores we might be able to have more control, and manage from other place
    stores.ui.setPageTransition(foundMatchingRoute && foundMatchingRoute.props.pageTransition);
  } else {
    stores.ui.setPageTransition();
  }

  return (
    <PageTransitionContainer
      pageTransition={stores.ui.pageTransition}
      pageTransitionDelay={stores.ui.pageTransitionDelay}
      routes={routes}
      transitionKey={key || pathname}
      ContentStyle={Page}
    >
      <Switch>
        {routes.map((RouteConfig) =>
          React.cloneElement(RouteConfig, {
            key: keyGenerator(RouteConfig.props),
          })
        )}
      </Switch>
    </PageTransitionContainer>
  );
});
