import { Driver, DriverRecord, getApiRoutes } from '@adac/core-model';
import { request } from '@adac/core-view';
import { useEffect, useState } from 'react';

type DriverResponse = {
  driver?: DriverRecord;
  isLoading: boolean;
};

const useSelectedDriver = (driverId: string): DriverResponse => {
  const [driver, setDriver] = useState<Driver>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getDriver = async () => {
      const url = getApiRoutes().driverAdmin.get(driverId);
      try {
        const data = await request<Driver>(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setDriver(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (!Number.isNaN(parseInt(driverId as string, 10))) {
      getDriver();
    }
  }, [driverId]);

  return {
    driver,
    isLoading,
  };
};

export default useSelectedDriver;
