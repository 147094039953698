import { useContext } from 'react';
import styled from 'styled-components';

import { Form, Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';

import { __ } from '@adac/core-model';
import {
  BackButton,
  Button,
  ButtonTitle,
  changePasswordCommit,
  ChangePasswordFields,
  ChangePasswordFormValues,
  ChangePasswordSchema,
  changePasswordStart,
  confirmToken,
  ConfirmTokenForm,
  ErrorConfirmFunc,
  ErrorMessage,
  LocalizableError,
  SubPage,
  Text,
  themeConfig,
  Title,
  useAsyncComponent,
  useFormikAuth,
  useLocation,
  useTokenMatcher,
} from '@adac/core-view';

import StoresContext, { useOverlay } from '../../stores';
import { LayoutStyles } from './Login';

const ProfileSubpage = styled(SubPage)`
  & > * {
    margin-bottom: 16px !important;
  }
`;

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export default () => {
  const { tokenKey, tokenValue, setTokenKey, setTokenValue, state } = useTokenMatcher();
  const {
    auth: { userName },
  } = useContext(StoresContext);
  const setOverlay = useOverlay();
  const { goTo } = useLocation();

  const onSubmit = async (values: ChangePasswordFormValues, actions: FormikActions<ChangePasswordFormValues>) => {
    try {
      await changePasswordCommit(values.currentPassword, values.newPassword, values.confirmPassword, tokenKey, tokenValue);
      goTo('/', { prefixed: false });
      setOverlay(
        <ErrorMessage icon='info' color={themeConfig.colors.progress}>
          {__('Password changed')}
        </ErrorMessage>
      );
    } catch (error) {
      setOverlay(<ErrorMessage>{__((error as Error)?.message)}</ErrorMessage>);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const { Component: StartChangeButton } = useAsyncComponent({
    onClick: async () => {
      const token = await changePasswordStart();
      setTokenKey(token);
    },
  });

  const onConfirmError: ErrorConfirmFunc = (error: LocalizableError) => {
    if (error.message.match(/(Too many attempts)|(expired)/)) setTokenKey('');
    setOverlay(<ErrorMessage>{__(error.toLocalizedString ? error.toLocalizedString() : error.toString())}</ErrorMessage>);
  };

  const { onSubmit: onConfirmSubmit } = useFormikAuth(confirmToken, onConfirmError, (token) => setTokenValue(token));

  if (state === 'validated') {
    return (
      <LayoutStyles>
        <Formik initialValues={initialValues} validationSchema={ChangePasswordSchema} onSubmit={onSubmit}>
          {(props: FormikProps<ChangePasswordFormValues>) => (
            <Form>
              <ProfileSubpage>
                <Title>{__('New Password form title')}</Title>
                <br />
                <Text>{__('New Password form description with Save')}</Text>
                <ChangePasswordFields vertical />

                <fieldset>
                  <Button isLoading={props.isSubmitting} cta type='submit' disabled={props.isSubmitting || !props.isValid} title={__('Save')} />

                  <BackButton small type='button'>
                    <ButtonTitle>{__('Cancel')}</ButtonTitle>
                  </BackButton>
                </fieldset>
              </ProfileSubpage>
            </Form>
          )}
        </Formik>
      </LayoutStyles>
    );
  }

  if (state === 'validating') {
    return (
      <LayoutStyles>
        <ConfirmTokenForm tokenKey={tokenKey} onSubmit={onConfirmSubmit} onCancel={() => setTokenKey('')}>
          <Title>
            {__('verificationMessageStart')}
            {userName}
            {__('verificationMessageEnd')}
          </Title>
          <br />
          <br />
          <Text>{__('Please enter confirmation token value')}</Text>
        </ConfirmTokenForm>
      </LayoutStyles>
    );
  }

  return (
    <LayoutStyles>
      <ProfileSubpage>
        <Title>
          {__('verificationMessageStart')}
          {userName}
          {__('verificationMessageEnd')}
        </Title>
      </ProfileSubpage>
      <fieldset>
        <StartChangeButton cta type='submit' title={__('Confirm')} />
        <BackButton small type='button'>
          <ButtonTitle>{__('Cancel')}</ButtonTitle>
        </BackButton>
      </fieldset>
    </LayoutStyles>
  );
};
